import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import CoverLayout from "../components/CoverLayout"
import {HeadComponent} from "../components/HeadComponent"

import { content } from './a-propos.module.scss'

const ReferencesPage = ({ data }) => {

  const coverImage = {
    image: data.image,
    alt: "© Chloé Bonnard - https://www.instagram.com/chloebonnardphoto/",
  }

  return (
    <Layout chClassName={content}>
      <CoverLayout coverImage={coverImage}>
        <h3>A Propos</h3>
        <p>
          Thomas Brosset est titulaire d'un Master 2 Urbanisme et Aménagement de
          l'Institut d’Urbanisme de Lyon. Titulaire du certificat AEU (Approche
          Environnementale de l'Urbanisme) Maitre d’Œuvre, ADEME.
          <hr />
          <h2>PUBLICATIONS ET AUTRES INTERVENTIONS</h2>
          <ul>
            <li>
              Brosset, T.; Maulat, J. (2013), friche, Les mots du foncier,
              ouvrage collectif sous la direction de Guilhem Boulay et Clotilde
              Buhot, ADEF.{" "}
            </li>
            <li>
              Burochin, J.-P.; Vallet, B.; Brédi , M.; Mallet, C.; Brosset, T.;
              Paparoditis, N. (2014), Detecting blind building façades from
              highly overlapping wide angle aerial imagery, ISPRS{" "}
            </li>
            <li>
              Chargée de formation URBAX, outil pédagogique pour une approche
              systémique de l’aménagement urbain à l’intention des agents des
              collectivités territoriales{" "}
            </li>
          </ul>
        </p>

        <h3>Nous contacter</h3>
        <p>contact@kadata.in - +33(0)1 72 77 00 42</p>
      </CoverLayout>
    </Layout>
  )}


export const pageQuery = graphql`{
  image: file(relativePath: {eq: "thomasbrosset.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`



export default ReferencesPage

export const Head = () => <HeadComponent title="Urbanisme & Cartographie" />